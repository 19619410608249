import {
	Card,
	Grid,
	Text,
	Title,
	Table,
	TableHead,
	TableRow,
	TableHeaderCell,
	TableBody,
	TableCell,
	Flex,
	DeltaBar,
	Metric,
	MarkerBar,
	CategoryBar,
} from '@tremor/react';

import React from 'react';

import API from './api';
import APIDataProvider from './api/APIDataProvider';

import {
	formatBigMoney,
	formatBigMoneyChange,
	niceNumber,
	percentChange,
} from './utils/format';

const barMarkers = [20, 60];

const formatData = (d) => {
	if (!d) return [];
	// const baseline = d.find((x) => x.assistant === 'Baseline');
	// const baselinePerHour = baseline.net / (baseline.minutes / 60);
	const out = d.map((x) => ({
		...x,
		perHour: x.net / (x.minutes / 60),
		// isBaseline: x.assistant === 'Baseline',
		// vsBaseline: x.net / (x.minutes / 60) - baselinePerHour,
		// vsBaselineTotal:
			// (x.net / (x.minutes / 60) - baselinePerHour) * (x.minutes / 60),
		// vsBaselinePct: x.net / (x.minutes / 60) / baselinePerHour - 1,
	}));

	// const max = Math.max(...out.map((x) => x.vsBaseline));
	// const bmarkers = barMarkers
	// 	.map((x) => (x / max) * 100)
	// 	.reduce((o, x, i) => {
	// 		o.push(x - (o[i - 1] || 0));
	// 		return o;
	// 	}, []);
	// bmarkers.push(100 - bmarkers.reduce((o, x) => o + x, 0));

	out.sort((a, b) => b.perHour - a.perHour);
	return out.map((x) => ({
		...x,
		// barMarkers: bmarkers,
		// barValue: (x.vsBaseline / max) * 100,
	}));
};

export default function () {
	return (
		<Grid numItemsMd={1} numItemsLg={1} className="gap-6 mt-6">
			<APIDataProvider method={API.timeTracking.bind(API)}>
				{(leaderboard) => (
					<Card>
						<Title>Leaderboard</Title>
						<Table className="mt-5">
							<TableHead>
								<TableRow>
									<TableHeaderCell className="dark:text-dark-tremor-content-emphasis">
										Name
									</TableHeaderCell>
									<TableHeaderCell className="dark:text-dark-tremor-content-emphasis">
										Hours
									</TableHeaderCell>
									<TableHeaderCell className="dark:text-dark-tremor-content-emphasis">
										Messages Per Hour
									</TableHeaderCell>
									<TableHeaderCell className="dark:text-dark-tremor-content-emphasis">
										Per Hour
									</TableHeaderCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{formatData(leaderboard).map((item) => (
									<TableRow key={item.assistant}>
										<TableCell className="dark:text-dark-tremor-content-emphasis">
											<Metric>{item.assistant}</Metric>
										</TableCell>
										<TableCell>
											<Metric>{niceNumber(item.minutes / 60, 0)}</Metric>
										</TableCell>
										<TableCell>
											<Metric>
												{item.messagesSent
													? niceNumber(
															item.messagesSent / (item.minutes / 60),
															0
													  )
													: ''}
											</Metric>
										</TableCell>
										<TableCell>
											<Metric>{formatBigMoney(item.perHour)}</Metric>
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</Card>
				)}
			</APIDataProvider>
		</Grid>
	);
}
