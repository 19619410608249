import { Grid, Flex, Button } from '@tremor/react';

import React, { useState } from 'react';

import { format, addHours } from 'date-fns';
import DaysSelect from './DaysSelect';
import DataProvider from './DataProvider';
import ContentSelector from './ContentSelector';
import Queue from './Queue';
import ContentEditor from './ContentEditor';
import api from '../api';

const sleep = (t) => new Promise((r) => setTimeout(r, t));

const dateTimeFormat = (date) => format(date, `yyyy-MM-dd'T'HH:mm`);

const sortMassDMQueue = (arr) => {
	const out = arr
		.sort((a, b) =>
			new Date(a.scheduledAt || a.createdAt) >
			new Date(b.scheduledAt || b.createdAt)
				? -1
				: 1
		)
		.filter((x) => !!x.price);
	return out;
};

const mapTopContentInfo = (topContent, massDM, sort, sortOrder) => {
	const content = topContent.map((x) => {
		const lastScheduled = massDM.find((y) => x.hash === y.hash);
		return {
			...x,
			lastScheduled: lastScheduled
				? lastScheduled.scheduledAt || lastScheduled.createdAt
				: null,
		};
	});
	if (sort) {
		console.log('Sort', sort);
		content.sort((a, b) => {
			if (a[sort] > b[sort]) return 1 * sortOrder;
			if (a[sort] == b[sort]) return 0;
			if (a[sort] < b[sort]) return -1 * sortOrder;
		});
	}
	return content;
};

export default function () {
	const [value, setValue] = useState(7);
	const [selectedToAdd, setSelected] = useState();
	const [toSchedule, setToSchedule] = useState([]);
	const [saving, setSaving] = useState(false);
	const [showDetail, setShowDetail] = useState('no');
	const [sort, sortChange] = useState();
	const [sortOrder, sortOrderChange] = useState(-1);

	const addSelectedToStagingList = () => {
		const tpl = {
			...selectedToAdd.template,
			scheduledAt: new Date(selectedToAdd.scheduleTime),
			hash: selectedToAdd.hash,
			overrideSKU: selectedToAdd.overrideSKU,
			id: Math.random(),
			originalID: selectedToAdd.template.id,
			type: 'pending',
		};
		const list = [tpl, ...toSchedule].sort((a, b) =>
			new Date(a.scheduledAt || a.createdAt) >
			new Date(b.scheduledAt || b.createdAt)
				? -1
				: 1
		);
		setToSchedule(list);
	};

	const stagingListToSchedule = () => {
		const sched = toSchedule.map((x) => ({
			price: x.price,
			text: x.text,
			lockedText: false,
			isCouplePeopleMedia: false,
			isScheduled: 1,
			scheduledDate: x.scheduledAt,
			userLists: ['71689', '71738'],
			userLists: ['fans', 'following'],
			mediaFiles: x.media.map((y) => y.id || y),
			releaseForms: x.releaseForms,
			previews: x.preview || x.previews,
			id: x.id,
			_hash: x.hash
		}));

		return sched;
	};

	const save = async () => {
		setSaving(true);
		const sched = stagingListToSchedule().reverse();
		for (const s of sched) {
			try {
				const { id } = s;
				const send = { ...s };
				send.id = undefined;
				const hash = send._hash;
				send._hash = undefined;
				const res = await api.scheduleQueueMessage(send, hash);
				console.log('RES', res);
				if (res.id) {
					removePending(id);
				}
				await sleep(15000);
			} catch (e) {
				console.error(e);
			}
		}
		setSaving(false);
	};

	const removePending = (id) => {
		setToSchedule((toSchedule) => toSchedule.filter((x) => x.id !== id));
	};

	return (
		<>
			<Flex>
				<DaysSelect
					value={value}
					onValueChange={setValue}
					showDetail={showDetail}
					showDetailChange={setShowDetail}
					sort={sort}
					sortChange={sortChange}
					sortOrder={sortOrder}
					sortOrderChange={sortOrderChange}
				></DaysSelect>
				{toSchedule.length > 0 ? (
					<Button onClick={save}>
						{saving
							? `Saving ${toSchedule.length}`
							: `Apply ${toSchedule.length} changes`}
					</Button>
				) : (
					''
				)}
			</Flex>
			<Grid numItemsMd={1} numItemsLg={3} className="gap-6 mt-6">
				<DataProvider days={value}>
					{(massDM, topContent, loadingTopContent) => (
						<>
							<ContentSelector
								content={mapTopContentInfo(
									loadingTopContent ? [] : topContent || [],
									massDM || [],
									sort,
									sortOrder
								)}
								setSelected={(x) => {
									const queue = sortMassDMQueue([
										...toSchedule,
										...(massDM || []),
									]);
									const date = queue[0]
										? new Date(queue[0].scheduledAt)
										: new Date();

									console.log(date);
									setSelected({
										...x,
										scheduleTime: dateTimeFormat(addHours(date, 1)),
									});
								}}
								showDetail={showDetail}
							></ContentSelector>
							<Queue
								content={sortMassDMQueue([...toSchedule, ...(massDM || [])])}
								removePending={removePending}
								showDetail={showDetail}
							></Queue>
							<div>
								{selectedToAdd ? (
									<ContentEditor
										selectedToAdd={selectedToAdd}
										addSelectedToStagingList={addSelectedToStagingList}
										setSelected={setSelected}
									></ContentEditor>
								) : (
									''
								)}
							</div>
						</>
					)}
				</DataProvider>
			</Grid>
		</>
	);
}
